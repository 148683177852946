import { useRef } from 'react';
import HomepageHeading from '../HomepageHeading';
import RecentDealsSlider from './recentDealsSlider';
import VisibilitySensor from 'react-visibility-sensor';
import texts from '@components/Homepage/en.json';

function RecentDeals() {
    const lottieRef = useRef();
    return (
        <VisibilitySensor partialVisibility>
            {({ isVisible }) => {
                isVisible
                    ? lottieRef.current?.play()
                    : lottieRef.current?.pause();
                return (
                    <section className="relative max-w-screen-maxScreen mx-auto bg-basicWhite flex flex-col items-center justify-center mt-12 md:mt-36">
                        <div
                            className={`z-50 md:mx-auto mx-6 md:absolute mb-6 md:mb-0 -top-8 md:-top-12 left-[45%] xl:left-auto xl:pl-20`}
                        >
                            <HomepageHeading
                                headline={texts?.RecentDealsHeading}
                                headlineFontClass="h5-semibold md:h4-semibold xl:h3-semibold"
                                headlineColorClass="text-primary-500"
                                headlineClassName="mb-[10px] xl:text-left"
                                taglineClassName="p5-regular md:p4-regular text-basicBlack"
                                tagline={texts?.RecentDealsDesc}
                                taglineColorClass="text-basicBlack"
                                htmlTag='h2'
                            />
                        </div>
                        <div className='w-full m-auto relative overflow-x-hidden'>
                            <RecentDealsSlider />
                        </div>
                    </section>
                );
            }}
        </VisibilitySensor>
    );
}

export default RecentDeals;
