import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { recentDeals } from '@helpers/homepageData';
import debounce from 'lodash.debounce';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid';
import Slide from './Slide';
const totalDeals = recentDeals.length;
const rangeStart = totalDeals;
const rangeEnd = 2 * totalDeals;

function RecentDealsSlider() {
    const sliderRef = useRef();
    const slides = [...recentDeals, ...recentDeals, ...recentDeals];
    const [slideWidth, setSlideWidth] = useState(0);
    const isAnimating = useRef(false);
    const [highlightedSlide, setHighlightedSlide] = useState(rangeStart);
    const [isPaused, setIsPaused] = useState(false);

    const handleResize = useCallback(
        debounce(() => {
            const isMobile = window.innerWidth < 768;
            requestAnimationFrame(() => {
                getCurrentSlideWidth(true);
                setTimeout(() => {
                    transitionSlider(highlightedSlide);
                }, 10);
            });
        }, 320),
        []
    );

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        let timeoutId;
        if (!isPaused) {
            timeoutId = setTimeout(() => {
                const nexthighlightedSlide =
                    highlightedSlide === rangeEnd - 1
                        ? rangeStart
                        : highlightedSlide + 1;
                transitionSlider(nexthighlightedSlide);
            }, 3000);
        }
        return () => clearTimeout(timeoutId);
    }, [highlightedSlide, isPaused]);

    const navigateToPrevSlide = () => {
        if (isPaused) {
            setIsPaused(false);
        }
        transitionSlider(highlightedSlide - 1);
    };
    const navigateToNextSlide = () => {
        if (isPaused) {
            setIsPaused(false);
        }
        transitionSlider(highlightedSlide + 1);
    };

    const handleSlideTransitionEnd = (e) => {
        if (isAnimating.current || e.propertyName !== 'transform') {
            return;
        }
        let adjustedSlideNumber = highlightedSlide;
        if (highlightedSlide < rangeStart) {
            adjustedSlideNumber = rangeEnd - 1;
        } else if (highlightedSlide >= rangeEnd) {
            adjustedSlideNumber = rangeStart;
        }
        if (adjustedSlideNumber === highlightedSlide) {
            return;
        }
        setHighlightedSlide(adjustedSlideNumber);
        isAnimating.current = true;
        sliderRef.current.classList.add('disable-transitions');
        sliderRef.current.style.transform = `translate3d(-${
            adjustedSlideNumber * getCurrentSlideWidth()
        }px, 0, 0)`;
        setTimeout(() => {
            sliderRef.current?.classList.remove('disable-transitions');
            isAnimating.current = false;
        }, 0);
    };

    const transitionSlider = (nexthighlightedSlide) => {
        setHighlightedSlide(nexthighlightedSlide);
        if (sliderRef.current?.style)
            sliderRef.current.style.transform = `translate3d(-${
                nexthighlightedSlide * getCurrentSlideWidth()
            }px,0,0)`;
    };

    const getCurrentSlideWidth = (forceUpdate = false) => {
        let currentSlideWidth = forceUpdate ? 0 : slideWidth;
        if (!slideWidth) {
            currentSlideWidth = document.querySelectorAll(
                '.recent-deal-item-wrapper:not(.active-slide)'
            )[1]?.offsetWidth;
            if (currentSlideWidth) {
                setSlideWidth(currentSlideWidth);
            }
        }
        return currentSlideWidth || 0;
    };
    return (
        <div className="overflow-hidden recent-slider shrink-0 sm:pt-0">
            <section className="recent-deals-slider-wrapper  container xl:max-w-[1190px] mx-auto px-4 md:px-6 xl:px-14">
                <ul
                    className={`recent-deals-slider relative flex flex-row items-end`}
                    ref={sliderRef}
                    onTransitionEnd={handleSlideTransitionEnd}
                    onMouseEnter={() => setIsPaused(true)}
                    onMouseLeave={() => setIsPaused(false)}
                    onClick={() => setIsPaused(!isPaused)}
                >
                    {slides.map((recentDeal, index) => (
                        <Fragment key={index}>
                            <Slide
                                {...{ index, highlightedSlide, recentDeal }}
                            />
                        </Fragment>
                    ))}
                </ul>

                <div
                    className={`action-buttons py-5 xl:py-9 flex justify-start`}
                >
                    <button
                        className="flex justify-center items-center w-[40px] h-[40px] p-3 text-primary-500  bg-basicWhite border-1 border-primary-500 rounded-lg hover:bg-primary-500 hover:text-basicWhite"
                        onClick={navigateToPrevSlide}
                    >
                        <ArrowLeftIcon className="w-5 h-5" />
                    </button>
                    <button
                        className="flex justify-center items-center w-[40px] h-[40px] p-3 ml-4 text-primary-500 bg-basicWhite border-1 border-primary-500 rounded-lg hover:bg-primary-500 hover:text-basicWhite"
                        onClick={navigateToNextSlide}
                    >
                        <ArrowRightIcon className="w-5 h-5" />
                    </button>
                </div>
            </section>
        </div>
    );
}

export default RecentDealsSlider;
