import Text from '@atoms/Text';
import React from 'react';
const HomepageHeading = ({
    headline,
    tagline,
    headlineClassName,
    taglineClassName,
    htmlTag,
    headlineColorClass = 'text-gray-900',
    taglineColorClass = 'text-primary-500',
    headlineFontClass = 'font-semibold xl:text-40px  lg:text-4xl sm:text-3xl sm:leading-snug text-2xl'
}) => {
    return (
        <>
            <Text htmlTag={htmlTag} content={headline} isInnerHtml={true}
                className={`  ${headlineFontClass} ${headlineColorClass} ${headlineClassName}`} />
            {tagline && <Text content={tagline} htmlTag='p' className={`${taglineColorClass} ${taglineClassName}`} />}
        </>
    )
};

export default HomepageHeading;
