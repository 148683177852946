import LazyImage from '@atoms/LazyImage';
import Text from '@atoms/Text';
import texts from '@components/Homepage/en.json';

const Slide = ({ index, highlightedSlide, recentDeal }) => {
    return (
        <li
            className={`${
                highlightedSlide === index
                    ? 'active-slide md:mr-[40px] mr-[21px] no-padding'
                    : ''
            } recent-deal-item-wrapper recent-deals-animated-item`}
        >
            <div
                className={`recent-deal-item recent-deals-animated-item bg-basicWhite flex flex-col justify-between rounded-3xl ${
                    highlightedSlide === index
                        ? 'shadow-active-recent-deal-item xl:w-[320px] w-[280px] max-h-[527px] bg-basicWhite border-[0.5px] border-secondary-200'
                        : 'xl:w-[320px] w-[280px] min-h-[280px] md:min-h-[340px] bg-primary-10'
                }`}
            >
                <header
                    className={`recent-deal-header flex flex-col gap-3 md:gap-4 ${
                        highlightedSlide === index
                            ? 'xl:px-6 xl:pt-10 md:px-6 pt-6 md:pt-8 px-4'
                            : 'xl:px-6 xl:pt-6 xl:pb-0 px-6 pt-6'
                    } recent-deals-animated-item relative justify-start`}
                >
                    <LazyImage
                        src={recentDeal.logo}
                        alt={recentDeal.name}
                        className={`overflow-hidden w-[64px] h-[64px] md:w-[96px] md:h-[96px] object-contain rounded-2xl recent-deal--logo recent-deals-animated-item border-1 border-light-basicBlack bg-basicWhite relative ${
                            highlightedSlide === index
                                ? '-left-2 xl:left-0'
                                : 'left-0'
                        }`}
                    />
                    <div
                        className={`${
                            highlightedSlide === index ? 'md:mt-2 mb-4' : 'my-0'
                        } mx-[2px]`}
                    >
                        <Text
                            htmlTag="h3"
                            content={recentDeal.name}
                            className={`p4-semibold md:p3-semibold recent-deals-animated-item text-gray-900`}
                        />
                        <Text
                            htmlTag="p"
                            content={recentDeal.subscribed}
                            className={`p5-regular md:p4-regular recent-deals-animated-item text-gray-700`}
                        />
                    </div>
                </header>
                <section
                    className={`${
                        highlightedSlide === index ? 'p-6' : 'px-6 pb-6'
                    } justify-end  recent-deal--content rounded-3xl`}
                >
                    <Text
                        htmlTag="p"
                        className={` ${
                            highlightedSlide === index
                                ? ''
                                : 'hidden overflow-hidden'
                        } p6-regular md:p5-regular mb-3 text-gray-700 recent-deal--description recent-deals-animated-item`}
                        content={recentDeal.description}
                    />

                    {highlightedSlide === index ? (
                        <hr className="border-b-[1px] border-gray-200" />
                    ) : (
                        ''
                    )}
                    <ul className={`flex mt-3 justify-between flex-row`}>
                        <li
                            className={`flex flex-col gap-2 recent-deals-animated-item`}
                        >
                            <Text
                                content={texts?.GrossReturns}
                                htmlTag="span"
                                className={`p6-regular md:p5-regular text-gray-700`}
                            />
                            <Text
                                content={recentDeal.grossReturns}
                                htmlTag="span"
                                className={`p5-semibold md:p4-semibold text-gray-900 recent-deals-animated-item`}
                            />
                        </li>
                        <li
                            className={`flex flex-col gap-2 recent-deals-animated-item`}
                        >
                            <Text
                                content={texts?.Tenure}
                                htmlTag="span"
                                className={`p6-regular md:p5-regular text-gray-700`}
                            />
                            <Text
                                content={recentDeal.tenure}
                                htmlTag="span"
                                className={`p5-semibold md:p4-semibold text-gray-900 recent-deals-animated-item`}
                            />
                        </li>
                    </ul>
                </section>
            </div>
        </li>
    );
};

export default Slide;
